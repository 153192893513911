import React, { useMemo } from 'react';
import { Icon, t } from '@core/idtek-component'
import _ from "lodash";
import "../style/header-custom.scss";
import { useAppContext } from "../hook/useAppContext";

const Footer = () => {
    const { appState } = useAppContext()!;

    const { location } = appState;

    return useMemo(() => {
        return (
            <footer className="footer">
                <div className="left">
                    {/* <div className="power">
                        {t("POWERED_BY")}
                    </div> */}
                    <div className="logo-footer">
                        <a target="_blank" href='https://idbooker.com'
                            style={{ display: "flex", alignItems: "center" }} rel="noreferrer">
                            <img className='logo-footer-custom' alt="" src={`${process.env.PUBLIC_URL}/idbooker5.png`} />
                            {/* <img className="img-2 logo-2-custom" style={{ marginTop: 15, marginLeft: 5 }} alt=""
                                src={`${process.env.PUBLIC_URL}/Group2.png`} /> */}
                        </a>
                    </div>
                    <div className='copyright-text'>
                        <a target="_blank" href="https://idtek.com.vn" rel="noreferrer">{t("COPYRING_TEXT")}</a>
                    </div>
                </div>
                <div className="right">
                    <div className="info">
                        <Icon type="mail" />
                        <a href={`mailto:${_.get(location, 'email')}`} className="link">
                            {t("EMAIL_US")}
                        </a>
                    </div>
                    <div className="info">
                        <Icon type="phone" rotate={90} />
                        <a href={`tel:${_.get(location, 'phone')}`} className="link">
                            {_.get(location, 'phoneDisplay')}
                        </a>
                    </div>
                    <div className="info">
                        <i className="fa fa-map-marker" aria-hidden="true" />
                        <a href={_.get(location, 'map')} className="link">
                            {t("MAPS_AND_HOURS")}
                        </a>
                    </div>
                    <a href={_.get(location, 'instagram')} className="social">
                        <Icon type="instagram" />
                    </a>
                    <a href={_.get(location, 'facebook')} className="social fb">
                        <Icon type="facebook" />
                    </a>
                </div>
            </footer>
        )
    }, [appState.location]);
};


export default Footer;