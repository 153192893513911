import { Alert, t } from "@core/idtek-component";
import vi_VN from "antd/es/locale/vi_VN";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import qs from "qs";

export default class AppUtil {
  static axios = axios;
  static moment = moment;

  static GLOBAL_DOMAIN_PATH =
    window.location.protocol + "//" + window.location.host;

  static GLOBAL_API_PATH = AppUtil.GLOBAL_DOMAIN_PATH + "/api";

  static GLOBAL_IMAGE_PATH = AppUtil.GLOBAL_DOMAIN_PATH + "/api/tenant/public";

  static DEFAULT_DURATION_PRICE_SERVICE = 60;
  static IS_BOOKING_RANGE_LIMIT = "n";

  static GLOBAL_IMAGE_DOWNLOAD =
    AppUtil.GLOBAL_DOMAIN_PATH + "/api/file/download";

  static PUBLIC_URL = "";

  static GLOBAL_DATE_FORMAT = "MMM/DD/YYYY";
  static GLOBAL_DATETIME_FORMAT = "hh:mm A MMM/DD/YYYY ";

  static GLOBAL_TENANT_API_PATH =
    AppUtil.GLOBAL_DOMAIN_PATH + "/api/tenant/public";

  static GLOBAL_SYMBOL_MONEY = "VND";
  static GLOBAL_LOCALE_MONEY = "vi";
  static GLOBAL_LOCALE_LANGUAGE = vi_VN;
  static TIME_WAITING_TRANSFER_BOOKING_ONLINE = "10m";
  static MAX_DURATION_SERVICE_BOOKING_ONLINE = 240;

  static getApi = async (api: string, params?: any | undefined | null) => {
    const url = AppUtil.GLOBAL_API_PATH + api;
    let response;
    try {
      response = await AppUtil.axios.get(url, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
    } catch {}
    return response;
  };

  static postApi = async (api: string, params?: any | undefined | null) => {
    const url = AppUtil.GLOBAL_API_PATH + api;
    let response;
    try {
      response = await AppUtil.axios.post(url, params);
    } catch {}
    return response;
  };

  static deleteApi = async (api: string, params?: any | undefined | null) => {
    const url = AppUtil.GLOBAL_API_PATH + api;
    let response;
    try {
      response = await AppUtil.axios.delete(url, params);
    } catch {}
    return response;
  };

  static GetSymbolCurrency = () => {
    return this.GLOBAL_SYMBOL_MONEY;
  };

  static GetCurrencyMoneyType = () => {
    return this.GLOBAL_LOCALE_MONEY;
  };

  static GetTimeConfig = (timeConfig: string) => {
    const regexUnit = /[YMDhms]/i;
    const regexTime = /[0-9]/gi;
    const unit = timeConfig.replaceAll(regexTime, "");
    const time = _.toNumber(timeConfig.replace(regexUnit, ""));
    return { time, unit };
  };

  static CalDateTimeConfig = (timeConfig: string) => {
    const regexUnit = /[YMDhms]/i;
    const regexTime = /[0-9]/gi;
    const unit = timeConfig.replaceAll(regexTime, "");
    const time = _.toNumber(timeConfig.replace(regexUnit, ""));
    let secondResult = 0;
    switch (unit) {
      case "H":
      case "h":
        secondResult = time * 3600;
        break;
      case "m":
      case "M":
        secondResult = time * 60;
        break;
      case "s":
        secondResult = time;
        break;
      default:
        secondResult = 0;
        break;
    }
    return secondResult;
  };

  static CalTimeLeftWaitingTransfer = (
    timeCreateBooking: string,
    timeCurrent: string,
    timeLeft?: number
  ) => {
    if (timeLeft) {
      const secondLeft =
        this.CalDateTimeConfig(this.TIME_WAITING_TRANSFER_BOOKING_ONLINE) -
        timeLeft;
      return secondLeft;
    }
    const timeBooking = moment(timeCreateBooking).format("yyyy-MM-DD HH:mm:ss");
    const timeNow = moment(timeCurrent).utc().format("yyyy-MM-DD HH:mm:ss");
    const diffTime = moment(timeNow).diff(moment(timeBooking), "seconds");
    const secondLeft =
      this.CalDateTimeConfig(this.TIME_WAITING_TRANSFER_BOOKING_ONLINE) -
      diffTime;
    return secondLeft > 0 ? secondLeft : 0;
  };

  static CalculatorPriceWithHour = (
    priceMinDuration: number,
    minDuration: number
  ) => {
    if (minDuration > AppUtil.DEFAULT_DURATION_PRICE_SERVICE) {
      return priceMinDuration;
    }
    return (
      (AppUtil.DEFAULT_DURATION_PRICE_SERVICE / minDuration) * priceMinDuration
    );
  };
  static LabelNumberByCurrency = (number: number | undefined) => {
    if (!number) return 0;
    const symbol = this.GetSymbolCurrency();
    const locale = this.GetCurrencyMoneyType();
    if (locale === "vi") {
      return this.ValueNumberByCurrency(number);
    }
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: symbol,
    }).format(number);
  };

  static ValueNumberByCurrency = (number: number | undefined) => {
    if (!number) return 0;
    const locale = this.GetCurrencyMoneyType();
    return new Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(
      number
    );
  };

  static ValueNumberByCurrencyBooking = (number: number | undefined) => {
    if (!number) return 0;
    const locale = this.GetCurrencyMoneyType();
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };

  static isNullOrUndefined = (value: any) => {
    return _.isNull(value) || _.isUndefined(value);
  };

  static ObjectToQuery(obj: any): string {
    return Object.entries(obj)
      .map(([key, val]) => `${key}=${val}`)
      .join("&");
  }

  static QueryToObject(str: string): any {
    const urlParams: any = new URLSearchParams(str);
    return Object.fromEntries(urlParams);
  }

  static ToastSuccess(message: string): void {
    Alert.Toast_info(t("NOTIFICATION"), message, 1);
  }

  static ToastError(message: string): void {
    Alert.Toast_info(t("ERROR"), message, 0);
  }

  static ToastWarning(message: string): void {
    Alert.Toast_info(t("WARNING"), message, 3);
  }

  static ToastWarningOptions(options: any): void {
    Alert.Toast_info(t("WARNING"), "", 3, options);
  }

  static RegexPhone(regex: string): any {
    let regexPhone = _.split(regex, ",") as any;
    _.map(regexPhone, (x, index) => {
      regexPhone[index] = _.toNumber(regexPhone[index]);
    });
    const delimiter = [];
    for (let i = 0; i < regexPhone.length; i++) {
      delimiter.push(" ");
    }
    const option = {
      blocks: regexPhone,
      delimiters: delimiter,
    };
    return option;
  }
}
