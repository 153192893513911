import React, { ReactElement } from 'react';
import './App.scss';
import { AppProvider } from "./context/AppContext";
import { CategoryProvider } from "./context/CategoryContext";
import Layout from "./page/Layout";
import { DrawerProvider } from "./context/DrawerContext";
import { BookingInfoProvider } from "./context/BookingInfoContext";
import { BrowserRouter } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import "./module/booking/style/booking.scss"
import 'moment/min/locales';
import { BookingServiceProvider } from './context/BookingServiceContext';

const App = (): ReactElement => {
    return (
        <div className="app-module" style={{ height: "100%" }}>

            <AppProvider>
                <CategoryProvider>
                    <DrawerProvider>
                        <BookingInfoProvider>
                            <BookingServiceProvider>
                                <BrowserRouter>
                                    <Layout />
                                </BrowserRouter>
                            </BookingServiceProvider>
                        </BookingInfoProvider>
                    </DrawerProvider>
                </CategoryProvider>
            </AppProvider>
        </div>
    );
}

export default App;
