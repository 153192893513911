import { t } from "@core/idtek-component";

export const BookingParam = ':booking';

export const HistoryMapping = {
    history: '/history',
};

export const BookingMapping = {
    bookingRouter: '/booking/:locationCode',
};


export const BookingApi = {
    createBooking: '/booking-online/create-booking',
    createBookingWaitingTransfer: '/booking-online/waiting-transfer',
    getBranch: '/booking-online/get-branch',
    getService: '/booking-online/get-service',
    getTime: '/booking-online/get-time',
    getTimeAvailable: '/booking-online/get-time-available',
    getMonthAvailable: '/booking-online/get-month-available',
    getTechnician: '/booking-online/get-technicians',
    getPaymentConfig: '/booking-online/get-payment-config',
    getCheckoutMethod: '/booking-online/get-check-out-methods',
    getReview: '/booking-online/get-review',
    getBooking: '/booking-online/get-booking',
    getDetailBooking: '/booking-online/show',
    postBranch: '/booking-online/branch',
    postTime: '/booking-online/time',
    postService: '/booking-online/service',
    postContact: '/booking-online/contact',
    postContactCache: '/booking-online/contact-cache',
    checkSessionId: '/booking-online/check-session',
    postReview: '/booking-online/review',
    postCache: "/booking-online/post-cache",
    postWaiting: "/booking-online/booking-waiting",
    getCache: "/booking-online/get-cache",
    getListReview: "/booking-online/get-list-review",
    getUrlVnPay: "/booking-online/get-url-vnpay",
    getUrlMomo: "/booking-online/get-url-momo",
    getPriceStrategy: '/booking-online/get-price-strategy-service',
    getPrice: '/booking-online/get-price-item',
    getPriceBooking: '/booking-online/get-price',
    getBookingWaitingTransfer: '/booking-online/get-booking-waiting-transfer',
    bookingTransferred: '/booking-online/success-transferred',
    cancelTransfer: '/booking-online/cancel-transfer',
    changeDurationGuest: '/booking-online/change-duration-service',
    callPriceService: '/booking-online/get-price-session-id',
    getAboutContentBranch: '/booking-online/get-content-about-branch'
};

export const infoFormModel = {
    fields: {
        name: { placeholder: `${t('TYPE_YOUR_NAME')}`, label: t('NAME') },
        description: { placeholder: `${t('NOTE')}...`, label: t('NOTE') },
        phone: { placeholder: `${t('TYPE_YOUR_PHONE_NUMBER')}`, label: t('PHONE_NUMBER') },
    }
};

export const TypeSegment = "service"


export const blankTime = 30;

export const KEY_SESSION_RELOAD = 'sessionReloadPage'