import loadable from '@loadable/component';
import { ConfigGlobal } from '@core/idtek-component';

ConfigGlobal.setIconMap?.({
    "phone": loadable(() => import('@ant-design/icons/lib/icons/PhoneOutlined')),
    "message": loadable(() => import('@ant-design/icons/lib/icons/MessageOutlined')),
    "unordered-list": loadable(() => import('@ant-design/icons/lib/icons/UnorderedListOutlined')),
    "check": loadable(() => import('@ant-design/icons/lib/icons/CheckOutlined')),
    "calendar": loadable(() => import('@ant-design/icons/lib/icons/CalendarOutlined')),
    "home": loadable(() => import('@ant-design/icons/lib/icons/HomeOutlined')),
    "plus": loadable(() => import('@ant-design/icons/lib/icons/PlusOutlined')),
    "minus": loadable(() => import('@ant-design/icons/lib/icons/MinusOutlined')),
    "dollar-circle": loadable(() => import('@ant-design/icons/lib/icons/DollarCircleOutlined')),
    "field-time": loadable(() => import('@ant-design/icons/lib/icons/FieldTimeOutlined')),
    "left": loadable(() => import('@ant-design/icons/lib/icons/LeftOutlined')),
    "right": loadable(() => import('@ant-design/icons/lib/icons/RightOutlined')),
    "check-circle-filled": loadable(() => import('@ant-design/icons/lib/icons/CheckCircleFilled')),
    "close-circle-filled": loadable(() => import('@ant-design/icons/lib/icons/CloseCircleFilled')),
    "arrow-left": loadable(() => import('@ant-design/icons/lib/icons/ArrowLeftOutlined')),
    "check-outline": loadable(() => import('@ant-design/icons/lib/icons/CheckOutlined')),
    "search": loadable(() => import('@ant-design/icons/lib/icons/SearchOutlined')),
    "close-outlined": loadable(() => import('@ant-design/icons/lib/icons/CloseOutlined')),
    "loading": loadable(() => import('@ant-design/icons/lib/icons/LoadingOutlined')),
    "arrow-down": loadable(() => import('@ant-design/icons/lib/icons/DownOutlined')),
    "info-circle": loadable(() => import('@ant-design/icons/lib/icons/InfoCircleOutlined')),
    "coppy": loadable(() => import('@ant-design/icons/lib/icons/CopyOutlined')),
});


