export const ReviewMapping = {
    history: '/review/:locationCode',
    historyDetail: '/review-detail/:locationCode',
};

export const ReviewApi = {};

export const BRANCH_OPENING = "BRANCH_OPENING";

export const BRANCH_CLOSED = "BRANCH_CLOSED";

export const BRANCH_INCOMING = "BRANCH_INCOMING";