import {createContext} from "react";
import {useMergeState} from "@core/idtek-component";
import {ICategoryContext, ICategoryContextData, ICategoryProvider} from "../interface/ICategoryContext";

export const CategoryContext = createContext<ICategoryContext | undefined>(undefined);

export const CategoryProvider = ({children}: ICategoryProvider): JSX.Element => {
    const [cateState, setCateState] = useMergeState<ICategoryContextData>({})

    return (
        <CategoryContext.Provider value={{cateState, setCateState}}>
            {children}
        </CategoryContext.Provider>
    );
}

