export const HistoryMapping = {
    history: '/history/:locationCode',
};

export const HistoryApi = {
    getHistoryWithDate: '/history-online/get-history-with-date',
    getHistory: '/history-online/get-history',
    getHistoryById: '/history-online/get-history-by-id',
    cancelBooking: '/booking-online/cancel',
};
