import React, { Suspense } from 'react';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import BookingRouter from "../module/booking/BookingRouter";
import LoginRouter from "../module/login/LoginRouter";
import { IRouterProp } from "../interface/IRouterProp";
import AppUtil from "../util/AppUtil";
import NotFound from "../page/NotFound";
import _ from "lodash";
import { Loading } from "@core/idtek-component";
import HistoryRouter from "../module/history/HistoryRouter";
import CheckInRouter from "../module/checkIn/CheckInRouter";
import ReviewRouter from "../module/review/ReviewRouter";


const RouterData = [
    ...BookingRouter,
    ...HistoryRouter,
    ...LoginRouter,
    ...CheckInRouter,
    ...ReviewRouter
];


const AppRouter = (props?: IRouterProp): JSX.Element => {
    const authRouterData = RouterData;
    const locationCurrent = useLocation();

    const getLocation = () => {
        const codeLocal = localStorage.getItem('locationCodeSetBefore');
        let redirectUrl = 'https://idbooker.com';
        if (locationCurrent.pathname === '/') {
            let locationCode = '';
            if (codeLocal) {
                locationCode = codeLocal;
            } else {
                window.location.href = redirectUrl;
            }
            return locationCode
        } else {
            const pathSplit = _.split(locationCurrent.pathname, '/');
            const locationCode = _.last(pathSplit);
            if (locationCode) {
                if (!codeLocal || locationCode !== codeLocal) {
                    localStorage.setItem('locationCodeSetBefore', locationCode);
                }
                return locationCode;
            } else {
                window.location.href = redirectUrl;
            }
        }

    }

    return (<>
        <Suspense fallback={<Loading type="ellipsis" />}>
            <Routes>
                {_.map(authRouterData, (route: any) => {
                    const { id, component: Component, exact } = route || {};
                    return (
                        <Route
                            key={id}
                            path={AppUtil.PUBLIC_URL + id}
                            element={<Component />}
                        >
                        </Route>
                    );
                })}
                <Route path="/not-found" element={<NotFound />} />
                <Route path="/" element={<Navigate replace to={`/booking/${getLocation()}?action=set-booking`} />} />
            </Routes>
        </Suspense>
    </>

    );
};

export default AppRouter;
