import React, { useMemo } from 'react';
import "../style/header-custom.scss";
import { Alert, Menu, Layout, t } from '@core/idtek-component';
import { useAppContext } from "../hook/useAppContext";
import { useBookingInfoContext } from "../hook/useBookingInfoContext";
import { useLocation, useNavigate } from "react-router-dom";
import AppUtil from "../util/AppUtil";
import _ from "lodash";


const AppHeader = () => {
    const { appState, setAppState } = useAppContext()!;
    const { location, user } = appState;
    const {
        setBookingInfoState,
    } = useBookingInfoContext()!;
    const navigate = useNavigate();

    const locationPath = useLocation();

    const onClick = (param: any) => {
        switch (_.get(param, "key")) {
            case '1':
                const url = `/booking/${location?.code}?action=set-booking`
                window.location.href = url;
                break;
            case '2':
                navigate && navigate({
                    pathname: `/history/${location?.code}`,
                    search: `?action=history`
                });
                break;

            case '3':
                const bookingUrl = `${window.location.origin}${AppUtil.PUBLIC_URL}/booking/${location?.code}?action=set-booking`
                const aboutUsUrl = `${window.location.origin}${AppUtil.PUBLIC_URL}/review/${location?.code}`
                const loginUrl = `${window.location.origin}${AppUtil.PUBLIC_URL}/bff/login`;

                navigate({
                    pathname: "/bff/login",
                    search: `?isBookingOnline=true&locationCode=${location?.code}
                    &bookingUrl=${encodeURIComponent(bookingUrl)}
                    &loginUrl=${encodeURIComponent(loginUrl)}
                    &returnUrl=${encodeURIComponent(bookingUrl)}
                    &aboutUsUrl=${encodeURIComponent(aboutUsUrl)}`
                })

                window.location.reload();
                break;

            case '4':
                onLogOut();
                break;

            case '5':
                navigate && navigate({
                    pathname: `/review/${location?.code}`,
                });
                break;
        }
    }

    const defaultSelectedFunction = (): string[] | undefined => {
        switch (locationPath.pathname) {
            case `${AppUtil.PUBLIC_URL}/booking/${location?.code}`:
                return ['1'];
            case `${AppUtil.PUBLIC_URL}/history/${location?.code}`:
                return ['2'];
            case `${AppUtil.PUBLIC_URL}/login/${location?.code}`:
                return ['3'];
            case `${AppUtil.PUBLIC_URL}/review/${location?.code}`:
                return ['5'];
        }
    }

    const onLogOut = async () => {
        const confirm = await Alert.Swal_confirm(
            t("SIGNING_OUT"),
            t("ARE_YOU_SURE_YOU_WANT_TO_SIGN_OUT?"),
            3
        );
        if (confirm.value === true) {

            setAppState({ user: undefined })
            setBookingInfoState({ phone: undefined, email: undefined, customerName: undefined })
            const bookingUrl = `${window.location.origin}${AppUtil.PUBLIC_URL}/booking/${location?.code}?action=set-booking`
            navigate && navigate({
                pathname: "/bff/logout",
                search: `?locationCode=${location?.code}&returnUrl=${encodeURIComponent(bookingUrl)}`
            })

            window.location.reload();
        }
    }

    const renderMenu = () => {
        return <>
            {!AppUtil.isNullOrUndefined(appState?.user?.userName) &&
                <Menu className="menu-bar-custom" mode="horizontal" selectedKeys={defaultSelectedKey}
                    onClick={onClick}>
                    <Menu.Item key="1">{t('BOOK').toUpperCase()}</Menu.Item>
                    <Menu.Item key="2">{t('HISTORY').toUpperCase()}</Menu.Item>
                    <Menu.Item key="5">{t('ABOUT_US').toUpperCase()}</Menu.Item>
                    <Menu.SubMenu key={"MENU"}
                        icon={<i style={{ color: "#2D2F32", marginTop: 3, marginRight: 8, fontSize: "inherit" }}
                            className="fa fa-user-circle-o"
                            aria-hidden="true" />}
                        title={appState?.user?.firstName?.toUpperCase()}
                        popupClassName={'sub-menu-logout'}
                    >
                        <Menu.Item key="4">{t('LOG_OUT')}</Menu.Item>
                    </Menu.SubMenu>
                </Menu>}

            {AppUtil.isNullOrUndefined(appState?.user?.userName) &&
                <Menu className="menu-bar-custom" mode="horizontal" selectedKeys={defaultSelectedKey}
                    onClick={onClick}>
                    <Menu.Item key="1">{t('BOOK').toUpperCase()}</Menu.Item>
                    <Menu.Item key="5">{t('ABOUT_US').toUpperCase()}</Menu.Item>
                    <Menu.Item key="3"><i style={{ color: "#2D2F32", marginTop: 3, marginRight: 8 }}
                        className="fa fa-user-circle-o"
                        aria-hidden="true" /> {t('LOGIN').toUpperCase()}</Menu.Item>
                </Menu>}
        </>
    }

    const defaultSelectedKey = defaultSelectedFunction();
    return useMemo(() => {
        const logoImage = AppUtil.GLOBAL_TENANT_API_PATH + "/download/" + _.get(location, "logoFileId")
        return (
            <Layout.Header className="location-header">
                <nav className="header-custom navbar navbar-fixed-top navbar-default ember-view" style={{
                    display: 'flex'
                }}>
                    <div className="logo">
                        <a target="_blank" href={_.get(location, 'logoHeaderUrl', '/')} rel="noreferrer"  >
                            <img alt="" src={logoImage} />
                        </a>
                    </div>
                    <div className="header-right">
                        {renderMenu()}
                    </div>

                </nav>
            </Layout.Header>
        )
    }
        , [location, defaultSelectedKey, user]);
};
export default AppHeader;