import React from 'react';

const NotFound = (): JSX.Element => {
    return (
        <div
            style={{
                background: '#fff',
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: '10px 10px 0 10px',
                padding: 10
            }}>
      <span style={{fontSize: 20}}>
        <div>Not found</div>
      </span>
        </div>
    );
};

export default NotFound;