import {createContext} from "react";
import {useMergeState} from "@core/idtek-component";
import {IDrawerContext, IDrawerContextData, IDrawerProvider} from "../interface/IDrawerContext";

export const DrawerContext = createContext<IDrawerContext | undefined>(undefined);

export const DrawerProvider = ({children}: IDrawerProvider): JSX.Element => {
    const [drawerState, setDrawerState] = useMergeState<IDrawerContextData>({
        openDrawer: false,
        openDrawerCate: false
    })

    return (
        <DrawerContext.Provider value={{drawerState, setDrawerState}}>
            {children}
        </DrawerContext.Provider>
    );
}

