import { useMergeState } from "@idtek/component-v2/lib/hook/IDHook";
import { IAppProvider, IBookingService, IBookingServiceContext } from "../interface/IAppContext";
import { createContext, useState } from "react";

export const BookingServiceContext = createContext<IBookingServiceContext | undefined>(undefined);

export const BookingServiceProvider = ({ children }: IAppProvider): JSX.Element => {
    const [bookingService, setBookingService] = useMergeState<IBookingService>({
        serviceChangeDuration: []
    });

    return (
        <BookingServiceContext.Provider value={{ bookingService, setBookingService }}>
            {children}
        </BookingServiceContext.Provider>
    );
}

