import { IAppContext, IAppContextData, IAppProvider } from "../interface/IAppContext";
import { createContext } from "react";
import { useMergeState } from "@core/idtek-component";

export const AppContext = createContext<IAppContext | undefined>(undefined);

export const AppProvider = ({ children }: IAppProvider): JSX.Element => {
    const defaultConfig = {}
    const [appState, setAppState] = useMergeState<IAppContextData>({
        loading: true,
        isShowThemes: true,
        configDefault: defaultConfig,
        isFirst: true,
        locationCode: localStorage.getItem('locationCodeSetBefore') ?? undefined
    })

    return (
        <AppContext.Provider value={{ appState, setAppState }}>
            {children}
        </AppContext.Provider>
    );
}

