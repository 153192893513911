import React, {ReactElement, useMemo} from 'react';
import {useAppContext} from "../hook/useAppContext";

const LoadingMask = (): ReactElement => {
    const {appState} = useAppContext()!;
    return useMemo(() => {
        if (!appState?.loading) {
            return <div style={{display: 'none'}}/>;
        }
        return (
            <div className="loading-mask-global-container">
                <div className="lds-ellipsis">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </div>
        );
    }, [appState?.loading]);
};

export default LoadingMask;
