import { LanguageDictionary } from "@core/idtek-component";
import _ from "lodash";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { createGlobalStyle } from "styled-components";
import Footer from "../component/Footer";
import { useAppContext } from "../hook/useAppContext";
import AppUtil from "../util/AppUtil";

import vi_VN from 'antd/es/locale/vi_VN';

import en_US from 'antd/es/locale/en_US';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AppHeader from "../component/AppHeader";
import AppRouter from "../component/AppRouter";
import HeaderMobile from "../component/HeaderMobile";
import { useBookingInfoContext } from "../hook/useBookingInfoContext";
import IBookingData from "../interface/IBooking";
import { BookingApi } from "../module/booking/Constant";
import '../style/loading.scss';
import LoadingMask from "./LoadingMask";


const Layout = (props: any): JSX.Element => {
    const { appState, setAppState } = useAppContext()!;
    const { bookingInfoState, setBookingInfoState } = useBookingInfoContext()!;
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get("sessionId");
    const bookingId = searchParams.get("bookingId");
    const action = searchParams.get("action");
    const [GlobalStyles, setGlobalStyles] = useState(createGlobalStyle`
      html {
      }
    `);
    const navigate = useNavigate();
    const locationCurrent = useLocation();

    useEffect(() => {
        (sessionId || bookingId) && fetchData();

    }, [sessionId, bookingId])

    const fetchData = async () => {
        const res = await AppUtil.getApi(`${BookingApi.getCache}`, {
            sessionId: sessionId,
            bookingId: bookingId
        })
        if (_.get(res, 'data.success')) {
            const result = _.get(res, 'data.result') as IBookingData;
            if (!_.isEmpty(result.guest) && _.size(result.guest) === 1) {
                _.forEach(result.guest, item => {
                    if (item.displayIndex) {
                        item.displayIndex = 0;
                    }
                })
            }
            setBookingInfoState(result);
        }
    }

    useEffect(() => {
        loadLanguage();
    }, []);
    const loadLanguage = async () => {
        const codeLocal = localStorage.getItem('locationCodeSetBefore');
        let redirectUrl = 'https://idbooker.com';
        let locationCodeFina = '';
        if (locationCurrent.pathname === '/') {
            let locationCode = '';
            if (codeLocal) {
                locationCode = codeLocal;
            } else {
                window.location.href = redirectUrl;
            }
            locationCodeFina = locationCode;
            navigate(`/booking/${locationCode}${action ? '?action=' + action : '?action=set-booking'}`)
        } else {
            const pathSplit = _.split(locationCurrent.pathname, '/');
            const locationCode = _.last(pathSplit);
            if (locationCode) {
                if (!codeLocal || locationCode !== codeLocal) {
                    localStorage.setItem('locationCodeSetBefore', locationCode);
                }
                navigate(`/${pathSplit[1] || 'booking'}/${locationCode}${window.location.search ? window.location.search : '?action=set-booking'}`)
                locationCodeFina = locationCode;
            } else {
                window.location.href = redirectUrl;
            }
        }
        const res = await AppUtil.getApi('/booking-online/get-language', { locationCode: locationCodeFina });
        if (_.get(res, 'data.success')) {
            const result = _.get(res, 'data.result');
            if (_.get(result, 'language') && !_.isEmpty(_.get(result, 'dictionary'))) {
                LanguageDictionary.setDictionary(
                    result.dictionary,
                    result.language
                )
                if (result.type === 'en') {
                    moment.locale('en');
                    AppUtil.GLOBAL_LOCALE_LANGUAGE = en_US
                }else{
                    moment.locale('vi');
                    AppUtil.GLOBAL_LOCALE_LANGUAGE = vi_VN
                }
            }
            if (_.get(result, 'dateFormat')) {
                AppUtil.GLOBAL_DATE_FORMAT = result.dateFormat
            }
            if (_.get(result, 'dateTimeFormat')) {
                AppUtil.GLOBAL_DATETIME_FORMAT = result.dateTimeFormat
            }
            setLoading(false)
        }
    }

    const checkLogin = async () => {
        const resAuthData = await AppUtil.getApi('/identity-online/check-login');
        if (_.get(resAuthData, "data.success")) {
            const data = _.get(resAuthData, "data.result");
            if (_.get(data, "authData")) {
                const dataUser = _.get(data, "authData.user")
                setAppState({ user: dataUser })
                setBookingInfoState({
                    phone: dataUser?.phone || dataUser?.phoneNumber,
                    customerName: dataUser?.customerName || dataUser?.fullName,
                    email: dataUser?.email || null,
                })
            }
        }
    }

    const fetchLocation = async () => {
        setAppState({ loading: true })
        const response = await AppUtil.getApi("/location-online/get-location-by-code"
            , { locationCode: appState.locationCode })
        if (_.get(response, "data.success") && _.get(response, "data.result")) {

            const location = _.get(response, "data.result");
            const GlobalStyles = createGlobalStyle`
              html {
                --theme-color: ${location.themeColor};
                --theme-background-color: ${location.themeBackgroundColor};
                --theme-color-boxshadow: ${location.themeShadowColor};
                --theme-color2: ${location.themeColor2}
              }
            `;
            setGlobalStyles(GlobalStyles)
            setAppState({ location: location, option: AppUtil.RegexPhone(location.regexPhone), locationCode: location.code })
        } else {
            //navigate({pathname: "/not-found"})
        }
        setTimeout(() => {
            setAppState({ loading: false })
        }, 300)

    }

    useEffect(() => {
        const checkIsMobile = navigator.userAgent.match(/Android/i) !== null ||
            navigator.userAgent.match(/webOS/i) !== null ||
            navigator.userAgent.match(/iPhone/i) !== null ||
            navigator.userAgent.match(/iPad/i) !== null ||
            navigator.userAgent.match(/iPod/i) !== null ||
            navigator.userAgent.match(/BlackBerry/i) !== null ||
            navigator.userAgent.match(/Windows Phone/i) !== null;
        setAppState({ isMobile: checkIsMobile })
    }, [navigator.userAgent])

    useEffect(() => {
        appState.locationCode && fetchLocation()
    }, [appState.locationCode]);

    useEffect(() => {
        checkLogin()
    }, []);

    if (loading) {
        return <>
            <div
                className={`body-v2 d-flex flex-fill row margin-unset-h loading-relative ${appState.isMobile ? 'full-loading-mobile' : 'full-loading'} `}
                style={{
                    // marginTop: appState.isShowThemes ? 20 : 0,
                    // marginBottom: appState.isShowThemes && !appState.isMobile ? 59 : 0,
                    // minHeight: appState.isShowThemes && !appState.isMobile ? 'calc(100vh - 160px)' : '100%'
                    overflowX: 'hidden'
                }}>
                <LoadingMask />
            </div>
        </>
    }

    return (
        <>
            <GlobalStyles />
            {appState.isShowThemes && (appState.isMobile ? <HeaderMobile /> : <AppHeader />)}
            <div
                className={`body-v2 d-flex flex-fill row margin-unset-h loading-relative ${appState.isMobile ? 'full-loading-mobile' : 'full-loading'} `}
                style={{
                    // marginTop: appState.isShowThemes ? 20 : 0,
                    // marginBottom: appState.isShowThemes && !appState.isMobile ? 59 : 0,
                    // minHeight: appState.isShowThemes && !appState.isMobile ? 'calc(100vh - 160px)' : '100%'
                    overflowX: 'hidden'
                }}>
                <LoadingMask />
                <AppRouter {...props} />


            </div>
            {appState.isShowThemes && <Footer />}

        </>
    );
};

export default Layout;