import {lazy} from 'react';
import {LoginMapping} from './Constant';

export const LoginView = lazy(() => import('./view/LoginView'));
export const SignUpView = lazy(() => import('./view/SignUpView'));
export const ForgotPasswordView = lazy(() => import('./view/ForgotPasswordView'));
// export const BranchBooking = lazy(() => import('./view/BranchBooking'));
// export const ServiceBooking = lazy(() => import('./view/ServiceBooking'));
// export const TechnicianBooking = lazy(() => import('./view/TechnicianBooking'));


export default [
    {id: LoginMapping.loginRouter, exact: true, component: LoginView},
    {id: LoginMapping.signUpRouter, exact: true, component: SignUpView},
    {id: LoginMapping.forgotPasswordRouter, exact: true, component: ForgotPasswordView},
    // {id: BookingMapping.locationBooking, exact: true, component: Booking},
    // {id: BookingMapping.branchBooking, exact: true, component: BranchBooking},
    // {id: BookingMapping.serviceBooking, exact: true, component: ServiceBooking},
    // {id: BookingMapping.technicianBooking, exact: true, component: TechnicianBooking},
    // {id: BookingMapping.timeBooking, exact: true, component: TimeBooking},
];