import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LoadingMask from "./page/LoadingMask";
import '@core/idtek-icon';
import { LanguageProvider } from '@core/idtek-component';


ReactDOM.render(
    <React.Suspense fallback={<LoadingMask />}>
        <LanguageProvider defaultLanguage={'en'}>
            <App />
        </LanguageProvider>

    </React.Suspense >,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
