import {IBookingInfoContext, IBookingInfoProvider} from "../interface/IBookingInfoContext";
import {createContext} from "react";
import {useMergeState} from "@core/idtek-component";
import IBookingData from "../interface/IBooking";

export const BookingInfoContext = createContext<IBookingInfoContext | undefined>(undefined);

export const BookingInfoProvider = ({children}: IBookingInfoProvider): JSX.Element => {
    const [bookingInfoState, setBookingInfoState] = useMergeState<IBookingData>({})

    return (
        <BookingInfoContext.Provider value={{bookingInfoState, setBookingInfoState}}>
            {children}
        </BookingInfoContext.Provider>
    );
}

